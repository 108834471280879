import { useQuery } from '@tanstack/react-query'
import { requestGET } from '../../services/request'
import {
  CaregiverModel,
  OrganizationModel,
  ResponseList,
} from '../../utils/models'
import { useAuth0 } from '@auth0/auth0-react'

export const useOrganizationView = () => {
  const auth = useAuth0()
  const {
    isLoading: orgLoading,
    error: orgError,
    data: orgData,
  } = useQuery<OrganizationModel>(
    [`organizationQuery`],
    async () => await requestGET(`user/organization`, undefined, auth),
  )
  const {
    isLoading: orgAdminsLoading,
    error: orgAdminsError,
    data: orgAdminsData,
  } = useQuery(
    [`organizationAdminsQuery`],
    async () =>
      await requestGET(`product/admins?skip=0&limit=100`, undefined, auth).then(
        (data: ResponseList<CaregiverModel>) => data.items,
      ),
  )

  // keep it for now to show how it looks with kvkNumber and address
  // const organization = {
  //   name: orgData?.name,
  //   contactEmail: `contact@orikami.nl`,
  //   organizationId: `org_gpKPhqrDOVqmcTnQ`,
  //   defaultStudyId: `testStudyId`,
  //   city: `Plaatsnaam`,
  //   street: `Straatnaam 21`,
  //   postalCode: `1234 AB`,
  // }

  return {
    orgData,
    orgLoading,
    orgError,
    orgAdminsData: orgAdminsData || [],
    orgAdminsLoading,
    orgAdminsError,
  }
}

import { AppShell } from '@mantine/core'
import { type PageProps } from 'gatsby'
import React, { FC, useState } from 'react'
import Intercom from '@intercom/messenger-js-sdk'

import SideMenu from './sideMenu'
import TopNavbar from './topnavbar'
import { Notifications } from '@mantine/notifications'
import { useAuth0 } from '@auth0/auth0-react'
import Loader from './loader'
import { Idle } from './Idle'
import { useUser } from '../hooks/user'

const Layout: FC<any> = ({ children }: PageProps) => {
  const [drawerState, setDrawerState] = useState(false)
  const { isLoading } = useAuth0()
  const { data: user } = useUser(true)

  if (isLoading) {
    return <Loader />
  }

  Intercom({
    app_id: `dabhufzi`,
    user_id: user?._id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: `${user?.firstname} ${user?.lastname}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
  })

  return (
    <AppShell
      padding="md"
      navbar={{
        width: 140,
        breakpoint: 0,
        collapsed: { mobile: false },
      }}
      header={{ height: 52 }}
    >
      <TopNavbar drawerState={drawerState} toggleDrawer={setDrawerState} />
      <SideMenu />
      <AppShell.Main>
        {children}
        <Idle />
      </AppShell.Main>
      <Notifications position={`bottom-right`} />
    </AppShell>
  )
}

export default Layout

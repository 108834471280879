import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { IconForbid } from '@tabler/icons-react'
import {
  Box,
  Button,
  Center,
  Container,
  Image,
  Space,
  Text,
  Title,
} from '@mantine/core'
import logo from '../static/orikami-logo.svg'
import * as classes from './NoPermissionsPage.module.css'
import { useTranslation } from 'react-i18next'

const NoPermissionsPage = () => {
  const { logout } = useAuth0()
  const { t } = useTranslation()

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: process.env.GATSBY_AUTH0_CALLBACK,
      },
    })
  }

  return (
    <Container size="xl" m="lg">
      <Center>
        <Box className={classes.inner}>
          <Image src={logo} alt="Orikami Logo" className={classes.image} />
          <Box className={classes.content}>
            <IconForbid size={64} style={{ color: `#c42626` }} />

            <Title>{t(`errorScreens.memberPermissionTitle`)}</Title>
            <Space h="lg" />
            <Text>{t(`errorScreens.memberPermissionText`)}</Text>
            <Space h="lg" />
            <Button onClick={handleLogout} size="lg">
              Logout
            </Button>
          </Box>
        </Box>
      </Center>
    </Container>
  )
}

export default NoPermissionsPage

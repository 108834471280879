import { useAuth0 } from '@auth0/auth0-react'

export const useIsMember = () => {
  const { user } = useAuth0()

  return (
    !!(user as any)?.[`${process.env.GATSBY_AUTH_NAMESPACE}/roles`].includes(
      `OrganizationAdmin`,
    ) ||
    !!(user as any)?.[`${process.env.GATSBY_AUTH_NAMESPACE}/roles`].includes(
      `ProductAdmin`,
    )
  )
}

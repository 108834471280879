import { useAuth0 } from '@auth0/auth0-react'
import { Button, Group, Modal, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIdleTimer } from 'react-idle-timer'

export const Idle = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const [remaining, setRemaining] = useState<number>(30_000)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { logout } = useAuth0()

  const onIdle = (event?: Event) => {
    console.log(`user is idle`, event)
    close()
    logout({
      logoutParams: {
        returnTo: process.env.GATSBY_AUTH0_CALLBACK,
      },
    })
  }

  const onActive = () => {
    console.log(`user is active`)

    close()
  }

  const onPrompt = () => {
    open()
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    timeout: 20 * 60 * 1000,
    promptBeforeIdle: 3 * 60 * 1000,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
    queryClient.invalidateQueries()
  }
  return (
    <Modal
      opened={opened}
      onClose={close}
      title={t(`idle.areYouStillThere`)}
      centered
    >
      <Text>{t(`idle.youWillBeLoggedOut`, { time: remaining })}</Text>
      <Group mt="xl">
        <Button onClick={handleStillHere}>{t(`idle.iAmStillHere`)}</Button>
      </Group>
    </Modal>
  )
}

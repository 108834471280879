import { useQuery, useQueryClient } from '@tanstack/react-query'
import { requestGET } from '../services/request'
import { useAuth0 } from '@auth0/auth0-react'
import { CaregiverModel } from '../utils/models'
import posthog from 'posthog-js'

export const useUser = (enabled = false) => {
  const auth = useAuth0()
  return useQuery<CaregiverModel>({
    queryKey: [`user`],
    queryFn: async () => {
      const result = await requestGET(`/user`, undefined, auth)
      const kraneUserId = result?.[`https://krane.orikami.tech/kraneUserId`]
      posthog.identify(kraneUserId || result.sub || `distinct_id`, {
        ...(result.sub && { sub: result.sub }),
        ...(result.email && { email: result.email }),
        ...(result.tenant && { tenant: result.tenant }),
        ...(result.studies && { project: result.studies[0] }),
      })
      return result
    },
    enabled,
  })
}
export const useM2MToken = () => {
  const queryClient = useQueryClient()
  const auth = useAuth0()
  return {
    getToken: () =>
      queryClient.fetchQuery({
        queryKey: [`adminToken`],
        queryFn: async () =>
          await requestGET(
            `organization/m2mTokenFromAdminToken`,
            undefined,
            auth,
          ),
      }),
  }
}

export interface organizationPermission {
  relation: string
  allowed: boolean
}
export const useOrganizationPermissions = () => {
  const auth = useAuth0()
  return useQuery<organizationPermission[]>({
    queryKey: [`organizationPermissions`],
    queryFn: async () => {
      return requestGET(`/user/organizationPermissions`, undefined, auth)
    },
  })
}

interface UserOrganization {
  id: string
  name: string
  display_name: string
  branding?: {
    logo_url?: string
    colors?: {
      page_background?: string
      primary?: string
    }
  }
}

interface UserOrganizations {
  items: UserOrganization[]
}

export const useUserOrganizations = () => {
  const auth = useAuth0()
  return useQuery<UserOrganizations>({
    queryKey: [`userOrganizations`],
    queryFn: async () => {
      return requestGET(`/user/organizations`, undefined, auth)
    },
  })
}

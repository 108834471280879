import React from 'react'
import { Loader as LoaderCircle } from '@mantine/core'
import styled from '@emotion/styled'

interface LoaderProps {
  size?: 'sm'
}

const Loader = ({ size }: LoaderProps) => {
  const StyledBox = styled(`div`)(() => ({
    display: `flex`,
    minHeight: size === `sm` ? `auto` : `100vh`,
    alignItems: `center`,
    justifyContent: `center`,
  }))

  return (
    <StyledBox>
      <LoaderCircle />
    </StyledBox>
  )
}

export default Loader

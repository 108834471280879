import styled from '@emotion/styled'
import { Grid, Group, AppShell, Title, Image, Select } from '@mantine/core'
import React, { useState } from 'react'
import LanguageDropdown from './languageDropdown/languageDropdown'
import { useTranslation } from 'react-i18next'
import { useOrganizationView } from './organizationView/useOrganizationView'
import { UserMenu } from './UserMenu'
import { useAuth0 } from '@auth0/auth0-react'
import { useUserOrganizations } from '../hooks/user'
import { IconBuilding } from '@tabler/icons-react'

const audience = process.env.GATSBY_AUTH0_AUDIENCE

interface NavbarProps {
  drawerState: boolean
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const TopNavbar = ({ drawerState, toggleDrawer }: NavbarProps) => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()
  const { orgData } = useOrganizationView()
  const { data: userOrganizations } = useUserOrganizations()

  const switchOrganization = (auth0OrgId: string | null) => {
    console.log(`switching organization`)
    console.log(auth0OrgId)
    if (auth0OrgId) {
      loginWithRedirect({
        authorizationParams: {
          organization: auth0OrgId,
          redirect_uri: process.env.GATSBY_AUTH0_CALLBACK || ``,
          audience,
        },
      })
    }
  }

  const OrganizationDropDown = () => {
    return (
      <Select
        data={userOrganizations?.items.map((org) => ({
          value: org.id,
          label: org.display_name,
        }))}
        value={orgData?.organizationId}
        onChange={switchOrganization}
        p={0}
        size="xs"
        style={{ marginTop: -4 }}
        // maw={50}
        leftSection={<IconBuilding />}
      />
    )
  }

  return (
    <AppShell.Header p="xs">
      <Group justify="space-between" wrap="nowrap">
        <Group pl="md" wrap="nowrap">
          {orgData?.logoUrl ? (
            <Image
              src={orgData?.logoUrl}
              alt={orgData?.displayName}
              fit="contain"
              w="auto"
              h={32}
            />
          ) : (
            <Title order={2}>{orgData?.displayName}</Title>
          )}
          <Title order={2}>-</Title>
          <Title order={2}>{t(`menu.administration`)}</Title>
          {(userOrganizations?.items.length || 0) > 1 && (
            <OrganizationDropDown />
          )}
        </Group>

        <Group wrap="nowrap">
          <LanguageDropdown />
          <UserMenu />
        </Group>
      </Group>
    </AppShell.Header>
  )
}

export default TopNavbar

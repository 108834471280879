import { Avatar, Group, Menu, UnstyledButton, Text, rem } from '@mantine/core'
import * as classes from './UserMenu.module.css'
import cx from 'clsx'
import { useState } from 'react'
import { IconChevronDown, IconLogout, IconShare } from '@tabler/icons-react'
import { useM2MToken, useUser } from '../hooks/user'
import { useOrganizationView } from './organizationView/useOrganizationView'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { showNotification } from '@mantine/notifications'

export const UserMenu = () => {
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const [userMenuOpened, setUserMenuOpened] = useState(false)
  const { data: user } = useUser(true)
  const { orgData } = useOrganizationView()

  const { getToken } = useM2MToken()

  const copyTheAccessToken = async function copyTheAccessToken() {
    const token = (await getToken()).slice(1, -1)
    await navigator.clipboard.writeText(token)
    showNotification({
      title: t(`global.success`),
      message: t(`menu.tokenCopied`),
      color: `green`,
    })
  }

  // Display e-email if no name (or very short name) is provided
  const userMenuLabel =
    (user?.firstname || []).length > 1 || (user?.lastname || []).length > 1
      ? `${user?.firstname} ${user?.lastname}`
      : user?.email

  return (
    <Menu
      width={260}
      position="bottom-end"
      transitionProps={{ transition: `pop-top-right` }}
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
    >
      <Menu.Target>
        <UnstyledButton
          mr="sm"
          className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
          pr="sm"
        >
          <Group gap={7} wrap="nowrap">
            <Avatar
              src={user?.image}
              alt={userMenuLabel}
              radius="xl"
              size={24}
            />
            <Text fw={500} size="sm" lh={1} mr={3}>
              {userMenuLabel}
            </Text>
            <IconChevronDown
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{t(`global.email`)}</Menu.Label>
        <Menu.Item component={Text}>{user?.email}</Menu.Item>
        <Menu.Label>{t(`menu.organization`)}</Menu.Label>
        <Menu.Item component={Text}>{orgData?.displayName}</Menu.Item>
        <Menu.Divider />
        <Menu.Item
          leftSection={
            <IconShare
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
          onClick={copyTheAccessToken}
        >
          {t(`menu.copyApiToken`)}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          leftSection={
            <IconLogout
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: process.env.GATSBY_AUTH0_CALLBACK,
              },
            })
          }
        >
          {t(`global.logout`)}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
